import React from 'react';
import PropTypes from 'prop-types';

import { PictureWrapper } from './Picture.style';

const propTypes = {
  fluid: PropTypes.shape({}),
  fixed: PropTypes.shape({}),
  objectFit: PropTypes.string,
  className: PropTypes.string.isRequired,
};

const defaultProps = {
  fluid: null,
  fixed: null,
  objectFit: 'cover',
};

const Picture = ({ fluid, fixed, className, objectFit }) => (
  <PictureWrapper objectFit={objectFit} className={className} fixed={fixed} fluid={fluid} />
);

Picture.propTypes = propTypes;
Picture.defaultProps = defaultProps;

export default Picture;
